import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';

export function ProtectedRoute() {
  const userToken = window.localStorage.getItem('__user_token__');
  const userType = localStorage.getItem('__user_type__'); 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!userToken) {
      navigate('/login');
    }
  }, [navigate, userToken]);

  const checkPermissions = (pathname) => {
    const permissions = {
      admin: ['/dashboard', '/jobs', '/search-candidates', '/create-candidate', '/update-candidate/:id', '/applied-candidates', '/blacklist-candidates', '/candidates', '/recruiters', '/clients', '/credits-usage'],
      recruiter: ['/dashboard', '/jobs', '/search-candidates', '/create-candidate', '/update-candidate/:id', '/applied-candidates', '/blacklist-candidates', '/candidates', '/credits-usage'],
      candidate: ['/dashboard', '/candidate/profile', '/candidate/jobs', '/candidate/applied-jobs', '/update-candidate/:id'],
    };

    return permissions[userType]?.some(path => {
      const regex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);
      return regex.test(pathname);
    });
  };

  if (!checkPermissions(location.pathname)) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
