import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Sidebar.css";
import {
  blackListSidebarActiveIcon,
  blackListSidebarGrayIcon,
  candidateSidebarActiveIcon,
  candidateSidebarGrayIcon,
  clientSidebarActiveIcon,
  clientSidebarGrayIcon,
  createCandidateActiveIcon,
  createCandidateGrayIcon,
  creditUsageSidebarActiveIcon,
  creditUsageSidebarGrayIcon,
  dashboardSidebarActiveIcon,
  dashboardSidebarGrayIcon,
  jobSidebarActiveIcon,
  jobSidebarGrayIcon,
  recruiterSidebarActiveIcon,
  recruiterSidebarGrayIcon,
} from "../../assets";

// AdminView component
function AdminView(props) {
  const { types, pathname } = props;

  return (
    <>
      <li className="submenu">
        <NavLink
          to="/dashboard"
          className={`sidebar-link ${
            pathname.includes("dashboard") ? "active" : ""
          }`}
        >
          <img
            src={
              pathname.includes("dashboard")
                ? dashboardSidebarActiveIcon
                : dashboardSidebarGrayIcon
            }
            alt=""
          />
          <span> Dashboard </span>
        </NavLink>

        <NavLink
          to="/jobs"
          className={`sidebar-link ${pathname === "/jobs" ? "active" : ""}`}
        >
          <img
            src={
              pathname === "/jobs" ? jobSidebarActiveIcon : jobSidebarGrayIcon
            }
            alt="jobSidebarIcon"
          />
          <span> Jobs </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/search-candidates"
          className={`sidebar-link ${
            pathname === "/search-candidates" || pathname === "/candidates"
              ? "active"
              : ""
          }`}
        >
          <img
            src={
              pathname === "/search-candidates" || pathname === "/candidates"
                ? candidateSidebarActiveIcon
                : candidateSidebarGrayIcon
            }
            alt="CandidateSidebarIcon"
          />
          <span> Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/create-candidate"
          className={`sidebar-link ${
            pathname === "/create-candidate" ? "active" : ""
          }`}
        >
          {/* <img src={pathname.includes('candidates') ? candidateSidebarActiveIcon : candidateSidebarGrayIcon} alt="CandidateSidebarIcon" /> */}
          <img
            src={
              pathname === "/create-candidate"
                ? createCandidateActiveIcon
                : createCandidateGrayIcon
            }
            alt="Create Recruiter Icon"
          />
          <span> Add Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/applied-candidates"
          className={`sidebar-link ${
            pathname === "/applied-candidates" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/applied-candidates"
                ? jobSidebarActiveIcon
                : jobSidebarGrayIcon
            }
            alt="jobSidebarIcon"
          />
          <span> Applied Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/blacklist-candidates"
          className={`sidebar-link ${
            pathname === "/blacklist-candidates" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/blacklist-candidates"
                ? blackListSidebarActiveIcon
                : blackListSidebarGrayIcon
            }
            alt="Blacklist icon"
          />
          <span> Blacklist </span> <span className="menu-arrow" />
        </NavLink>

        <hr className="divider" />
        <NavLink
          to="/recruiters"
          className={`sidebar-link ${
            pathname === "/recruiters" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/recruiters"
                ? recruiterSidebarActiveIcon
                : recruiterSidebarGrayIcon
            }
            alt="recruiterSidebarIcon"
          />
          <span> Recruiters </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/clients"
          className={`sidebar-link ${pathname === "/clients" ? "active" : ""}`}
        >
          <img
            src={
              pathname === "/clients"
                ? clientSidebarActiveIcon
                : clientSidebarGrayIcon
            }
            alt="clientSidebarIcon"
          />
          <span> Clients </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/credits-usage"
          className={`sidebar-link ${
            pathname === "/credits-usage" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/credits-usage"
                ? creditUsageSidebarActiveIcon
                : creditUsageSidebarGrayIcon
            }
            alt="creditUsageSidebarIcon"
          />
          <span> Credits & Usage </span> <span className="menu-arrow" />
        </NavLink>
      </li>
    </>
  );
}

// Recruiter component
function Recruiter(props) {
  const { types, pathname } = props;

  return (
    <>
      <li className="submenu">
        <NavLink
          to="/dashboard"
          className={`sidebar-link ${
            pathname.includes("dashboard") ? "active" : ""
          }`}
        >
          <img
            src={
              pathname.includes("dashboard")
                ? dashboardSidebarActiveIcon
                : dashboardSidebarGrayIcon
            }
            alt=""
          />
          <span> Dashboard </span>
        </NavLink>

        <NavLink
          to="/jobs"
          className={`sidebar-link ${pathname === "/jobs" ? "active" : ""}`}
        >
          <img
            src={
              pathname === "/jobs" ? jobSidebarActiveIcon : jobSidebarGrayIcon
            }
            alt="jobSidebarIcon"
          />
          <span> Jobs </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/search-candidates"
          className={`sidebar-link ${
            pathname === "/search-candidates" || pathname === "/candidates"
              ? "active"
              : ""
          }`}
        >
          <img
            src={
              pathname === "/search-candidates" || pathname === "/candidates"
                ? candidateSidebarActiveIcon
                : candidateSidebarGrayIcon
            }
            alt="CandidateSidebarIcon"
          />
          <span> Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/create-candidate"
          className={`sidebar-link ${
            pathname === "/create-candidate" ? "active" : ""
          }`}
        >
          {/* <img src={pathname.includes('candidates') ? candidateSidebarActiveIcon : candidateSidebarGrayIcon} alt="CandidateSidebarIcon" /> */}
          <img
            src={
              pathname === "/create-candidate"
                ? createCandidateActiveIcon
                : createCandidateGrayIcon
            }
            alt="Create Recruiter Icon"
          />
          <span> Add Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/applied-candidates"
          className={`sidebar-link ${
            pathname === "/applied-candidates" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/applied-candidates"
                ? jobSidebarActiveIcon
                : jobSidebarGrayIcon
            }
            alt="jobSidebarIcon"
          />
          <span> Applied Candidates </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/blacklist-candidates"
          className={`sidebar-link ${
            pathname === "/blacklist-candidates" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/blacklist-candidates"
                ? blackListSidebarActiveIcon
                : blackListSidebarGrayIcon
            }
            alt="Blacklist icon"
          />
          <span> Blacklist </span> <span className="menu-arrow" />
        </NavLink>
        <hr className="divider" />

        <NavLink
          to="/credits-usage"
          className={`sidebar-link ${
            pathname === "/credits-usage" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/credits-usage"
                ? creditUsageSidebarActiveIcon
                : creditUsageSidebarGrayIcon
            }
            alt="creditUsageSidebarIcon"
          />
          <span> Credits & Usage </span> <span className="menu-arrow" />
        </NavLink>
      </li>
    </>
  );
}

function Candidate(props) {
  const { types, pathname } = props;

  return (
    <>
      <li className="submenu">
        <NavLink
          to="/candidate/jobs"
          className={`sidebar-link ${
            pathname === "/candidate/jobs" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/candidate/jobs"
                ? jobSidebarActiveIcon
                : jobSidebarGrayIcon
            }
            alt="jobSidebarIcon"
          />
          <span> Jobs </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/candidate/applied-jobs"
          className={`sidebar-link ${
            pathname === "/candidate/applied-jobs" ? "active" : ""
          }`}
        >
          <img
            src={
              pathname === "/candidate/applied-jobs"
                ? candidateSidebarActiveIcon
                : candidateSidebarGrayIcon
            }
            alt="CandidateSidebarIcon"
          />
          <span> Applied Jobs </span> <span className="menu-arrow" />
        </NavLink>

        <NavLink
          to="/candidate/profile"
          className={`sidebar-link ${
            pathname === "/candidate/profile" ||
            pathname.includes("/update-candidate")
              ? "active"
              : ""
          }`}
        >
          <img
            src={
              pathname === "/candidate/profile" ||
              pathname.includes("/update-candidate")
                ? recruiterSidebarActiveIcon
                : recruiterSidebarGrayIcon
            }
            alt="recruiterSidebarIcon"
          />
          <span> My Profile </span> <span className="menu-arrow" />
        </NavLink>
      </li>
    </>
  );
}

class Sidebar extends React.Component {
  render() {
    const type = localStorage.getItem("__user_type__");
    const pathname = window.location.pathname;
    const s3url = process.env.REACT_APP_AWS_IMAGE_URL;

    return (
      <div
        className={`sidebar ${this.props.menuOpen ? "active" : ""}`}
        id="sidebar"
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* Company Logo and Name */}
              <li className="sidebar-header">
                <div className="logo1">
                  <img
                    src={`${s3url}adhaan/Logo_-_White_-_Uncropped.png`}
                    alt=""
                    width="100px"
                    height="50px"
                  />
                </div>
                <div className="company-name">Adhaan Solution Pvt. Ltd</div>
              </li>
              <hr className="divider" />
              {/* Dashboard Link */}
              {/* <li>
                <Link to="/dashboard"  className={`sidebar-link ${pathname.includes('dashboard') ? "active" : ""}`}>
                  <img src={pathname.includes('dashboard') ? dashboardSidebarActiveIcon : dashboardSidebarGrayIcon} alt="" />
                  <span> Dashboard </span>
                </Link>
              </li> */}
              {/* AdminView, Recruiter and Candidate components */}
              {type === "admin" && (
                <AdminView types={type} pathname={pathname} />
              )}
              {type === "recruiter" && (
                <Recruiter types={type} pathname={pathname} />
              )}
              {type === "candidate" && (
                <Candidate types={type} pathname={pathname} />
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
