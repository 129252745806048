/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Collapse,
  Button,
  Checkbox,
  Col,
  Flex,
  Radio,
  Row,
  Space,
  Select,
  Pagination,
  Spin,
  Tag,
  Input,
} from "antd";
import CandidateCard from "../components/CandidateCard/CandidateCard";
import {
  filterIcon,
  filterAquaIcon,
  LeadingIcon,
  angleleftIcon,
} from "../assets";
import CandidateLocationsModal from "../components/CandidateLocationsModal";
import CandidateDetails from "./CandidateDetails";
import SVGClose from "../assets/SVGClose";
import "../styles/CandidatePage.css";
import { get, post } from "../lib/Api";
import useMediaQuery from "../hooks/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import ConfirmationModal from "../components/modal/ConfirmationModal";
import NoData from "../components/NoData/NoData";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const { Option, OptGroup } = Select;

const CandidatePage = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const { searchData } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [isDownloadAllModalOpen, setIsDownloadAllModalOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [allCandidates, setAllCandidates] = useState(0);
  const [genderCount, setGenderCount] = useState({
    male: 0,
    female: 0,
    other: 0,
  });
  const [pageSize, setPageSize] = useState(10);

  const [languagesDropData, setLanguagesDropData] = useState([]);
  const [searchData, setSearchData] = useState(
    location.state?.searchData || {}
  );

  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedCityItems, setSelectedCityItems] = useState(
    searchData?.currentCity || []
  );
  const [selectedStates, setSelectedStates] = useState([]);
  const [cityToStateMap, setCityToStateMap] = useState({});
  const [stateToCityMap, setStateToCityMap] = useState({});
  const [statesWithCities, setStatesWithCities] = useState([]);

  const [currentCandidate, setCurrentCandidate] = useState(null);

  useEffect(() => {
    if (!location.state?.searchData) {
      navigate("/search-candidates");
    }
  }, []);

  const fetchData = async (page) => {
    setLoading(true);
    const data = { ...searchData, page_no: currentPage, page_size: pageSize };
    try {
      const response = await post("candidate/list", data, "", true);
      if (response?.status === true) {
        setCandidates(response.candidate_profile_list.results);
        setTotalCandidates(response.count);
        setGenderCount(response.gender);
        setAllCandidates(response.candidate_count);
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
      setLoading(false);
    }
  };

  const fetchStatesWithCities = async () => {
    setLoading(true);
    const res = await get(`master/state-wise-city-list`, {}, {}, true);
    if (res?.status === true) {
      setStatesWithCities(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchLanguagesDropData = async () => {
    setLoading(true);
    const res = await get(`master/language-list`, "", {}, true);
    if (res?.status === true) {
      setLanguagesDropData(res?.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const hasValidSearchData = (data) => {
    return Object.values(data).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== "";
    });
  };

  useEffect(() => {
    if (searchData && hasValidSearchData(searchData)) {
      fetchData(currentPage);
    }
  }, [currentPage, pageSize, searchData]);

  useEffect(() => {
    fetchLanguagesDropData();
    fetchStatesWithCities();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(current);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "minExp" || id === "maxExp") {
      if (/^\d+(\.\d{0,2})?$/.test(value) || value === "") {
        setSearchData({
          ...searchData,
          [id]: value,
        });
      }
    } else {
      setSearchData({
        ...searchData,
        [id]: value,
      });
    }
  };

  const handleSelectChange = (value, option) => {
    setSearchData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  // const handleSearchTypeChange = (e) => {
  //   setSearchData({
  //     ...searchData,
  //     searchType: e.target.value,
  //   });
  // };

  const handleGenderTypeChange = (e) => {
    setSearchData({
      ...searchData,
      gender: e.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (
      (e.key === "Enter" && keywordInputValue.trim() !== "") ||
      (e.key === "Tab" && keywordInputValue.trim() !== "")
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  const handleKeywordInputChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const handleKeywordRemove = (index) => {
    setSearchData((prevData) => {
      // if (prevData.keywords.length > 1) {
      return {
        ...prevData,
        keywords: prevData.keywords.filter((_, i) => i !== index),
      };
      // } else {
      //   Toast({ message: "At least one keyword is required", type: "error" });
      //   return prevData;
      // }
    });
  };

  const handleResetData = async () => {
    setSearchData({
      // searchType: "any",
      keywords: [],
      currentCity: [],
      minExp: "",
      maxExp: "",
      minSalary: "",
      maxSalary: "",
      highestEducaton: [],
      industries: [],
      Gender: "",
      languages: [],
    });
  };

  useEffect(() => {
    const newCityToStateMap = {};
    const newStateToCityMap = {};
    statesWithCities.forEach((state) => {
      newStateToCityMap[state.id] = state.cities.map((city) => city.id);
      state.cities.forEach((city) => {
        newCityToStateMap[city.id] = state.id;
      });
    });
    setCityToStateMap(newCityToStateMap);
    setStateToCityMap(newStateToCityMap);
  }, [statesWithCities]);

  // const handleCityChange = (values) => {
  //   setSelectedCityItems(values);
  //   setSearchData((prevData) => ({ ...prevData, currentCity: values }));
  // };

  const handleCityChange = (value) => {
    setSelectedCityItems(value);

    const newlySelectedStates = statesWithCities
      .filter((state) => state.cities.every((city) => value.includes(city.id)))
      .map((state) => state.id);

    setSelectedStates(newlySelectedStates);
    setSearchData({
      ...searchData,
      currentCity: value,
    });
  };

  const handleStateChange = (stateId, stateCities) => {
    const cityIds = stateCities.map((city) => city.id);

    if (selectedStates.includes(stateId)) {
      setSelectedCityItems((prev) =>
        prev.filter((cityId) => !cityIds.includes(cityId))
      );
      setSelectedStates((prev) => prev.filter((id) => id !== stateId));

      setSearchData((prev) => ({
        ...prev,
        currentCity: prev.currentCity.filter(
          (cityId) => !cityIds.includes(cityId)
        ),
      }));
    } else {
      const newCityItems = [...new Set([...selectedCityItems, ...cityIds])];
      setSelectedCityItems(newCityItems);
      setSelectedStates((prev) => [...prev, stateId]);

      setSearchData((prev) => ({
        ...prev,
        currentCity: newCityItems,
      }));
    }
  };

  const handleCitySelect = (value) => {
    const stateId = cityToStateMap[value];
    if (stateId) {
      setSelectedCityItems((prev) => [...new Set([...prev, value, stateId])]);
    }
  };

  const handleCityDeselect = (value) => {
    const stateId = cityToStateMap[value];
    if (stateId) {
      const remainingCities = stateToCityMap[stateId].filter(
        (id) => id !== value
      );
      if (!remainingCities.some((id) => selectedCityItems.includes(id))) {
        setSelectedCityItems((prev) => prev.filter((item) => item !== stateId));
      }
    }
  };

  // const getOptions = () => {
  //   return statesWithCities.map((state) => (
  //     <OptGroup
  //       key={state.id}
  //       label={
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <Checkbox
  //             disabled
  //             checked={selectedCityItems.includes(state.id)}
  //             style={{ marginRight: 8 }}
  //           />
  //           {state.name}
  //         </div>
  //       }
  //     >
  //       {state.cities.map((city) => (
  //         <Option key={city.id} value={city.id}>
  //           {city.name}
  //         </Option>
  //       ))}
  //     </OptGroup>
  //   ));
  // };

  const getOptions = () => {
    return statesWithCities
      .filter((state) => state.cities.length > 0)
      .map((state) => {
        const allCitiesSelected = state.cities.every((city) =>
          selectedCityItems.includes(city.id)
        );

        return (
          <OptGroup
            key={state.id}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={allCitiesSelected}
                  onChange={() => handleStateChange(state.id, state.cities)}
                  style={{ marginRight: 8 }}
                />
                {state.name}
              </div>
            }
          >
            {state.cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </OptGroup>
        );
      });
  };

  const customTagRender = ({ label, value, closable, onClose }) => {
    const shouldHide = label.startsWith("s-");
    if (shouldHide) {
      return null;
    }
    return (
      <Tag closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const filterOption = (input, option) => {
    const state = statesWithCities.find((state) =>
      state.cities.some((city) => city.id === option.value)
    );

    const cityNameMatches = option?.children
      ?.toLowerCase()
      .includes(input.toLowerCase());
    const stateNameMatches = state?.name
      ?.toLowerCase()
      .includes(input.toLowerCase());

    return cityNameMatches || stateNameMatches;
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedCandidates(candidates.map((candidate) => candidate.id));
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleCandidateSelectChange = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const handleDownload = async () => {
    if (selectedCandidates.length === 0) {
      Toast({
        message: "Please select candidate to download report",
        type: "error",
      });
      return;
    }
    setLoading(true);
    const data = { candidate_id: selectedCandidates };

    try {
      const res = await post(`candidate/bulk-export`, data, "", true);
      if (res?.status === true) {
        // Toast({ message: "Report successfully downloaded", type: "success" });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
      } else {
        Toast({
          message: res?.message || "Download failed",
          type: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      Toast({
        message: error.response?.message || "Download failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleDownloadAll = async () => {
    if (!hasValidSearchData(searchData)) {
      Toast({
        message: "Please fill in at least one field to download all candidates",
        type: "error",
      });
      return;
    }
    setLoading(true);

    try {
      const res = await post(
        `candidate/download-data-sheet`,
        searchData,
        "",
        true
      );
      if (res?.status === true) {
        // Toast({ message: "Report successfully downloaded", type: "success" });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
      } else {
        Toast({
          message: res?.message || "Download failed",
          type: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      Toast({
        message: error.response?.message || "Download failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const showModal = () => setIsModalOpen(true);
  const showFilterModal = () => setIsFilterModalOpen(!isFilterModalOpen);
  const handleOk = () => setIsModalOpen(false);

  const handleVisibleDetails = (candidate) => {
    setCurrentCandidate(candidate);
    setVisibleDetails(true);
  };

  const handleCloseDetails = () => {
    setVisibleDetails(false);
    setCurrentCandidate(null);
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const onCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const items = [
    {
      key: "1",
      label: "Must have Keywords",
      children: (
        <div className="checkbox-list chips">
          <Flex
            wrap
            gap="small"
            className="chips-button"
            ref={inputRef}
            // style={{ marginBottom: "10px" }}
          >
            {/* <Button type="primary" ghost icon={<CloseOutlined />}>
              Designer
            </Button>*/}

            <Input
              id="Keywords"
              placeholder="Type Keyword to search"
              value={keywordInputValue}
              onChange={handleKeywordInputChange}
              onKeyDown={handleKeyPress}
            />
            <Space direction="horizontal" className="tags-wrapper">
              {searchData?.keywords?.map((val, index) => (
                <Tag
                  // closeIcon={<SVGClose />}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleKeywordRemove(index);
                  }}
                  color="success"
                  key={index}
                >
                  {val}
                </Tag>
              ))}
            </Space>
          </Flex>

          {/* <Button type="link" block onClick={showModal}>
            View More
          </Button> */}
        </div>
      ),
    },
    {
      key: "2",
      label: "Current City/Area",
      children: (
        <div className="checkbox-list">
          {/* <Checkbox onChange={onCheckbox}>
            Bengaluru <small>(3469)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Hosur <small>(8)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Hyderabad <small>(1200)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Pune <small>(4000)</small>
          </Checkbox>
          <Button type="link" block onClick={showModal}>
            View More
          </Button> */}

          {/* <Select
            id="currentCity"
            mode="multiple"
            placeholder="Select a Current City"
            className="candidate-select-input"
            onChange={(e) => {
              handleSelectChange(e, { id: "currentCity" });
            }}
            // tagRender={tagRender}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={citiesDropData?.map((city) => ({
              value: city.id,
              label: city.name,
              id: "currentCity",
            }))}
            value={searchData?.currentCity || undefined}
            style={{
              height: "auto",
            }}
          /> */}

          {/* <Select
            mode="multiple"
            placeholder="Select cities"
            value={selectedCityItems}
            onChange={handleCityChange}
            onSelect={handleCitySelect}
            onDeselect={handleCityDeselect}
            style={{ width: "100%" }}
            tagRender={customTagRender}
            showSearch
            filterOption={filterOption}
          >
            {getOptions()}
          </Select> */}

          <Select
            mode="multiple"
            placeholder="Select cities"
            value={selectedCityItems}
            onChange={handleCityChange}
            style={{ width: "100%", height: "auto" }}
            showSearch
            filterOption={filterOption}
          >
            {getOptions()}
          </Select>
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: "Industries",
    //   children: (
    //     <div className="checkbox-list">
    //       <Checkbox onChange={onCheckbox}>
    //         IT<small>(3469)</small>
    //       </Checkbox>
    //       <Checkbox onChange={onCheckbox}>
    //         Oil & Gas <small>(4000)</small>
    //       </Checkbox>
    //       <Button type="link" block onClick={showModal}>
    //         View More
    //       </Button>
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: "Gender",
      children: (
        <div className="checkbox-list">
          <Radio.Group
            onChange={handleGenderTypeChange}
            value={searchData.gender}
          >
            <Space direction="vertical">
              <Radio value={"male"}>
                Male <small>({genderCount?.male})</small>
              </Radio>
              <Radio value={"female"}>
                Female <small>({genderCount?.female})</small>
              </Radio>
              <Radio value={"other"}>
                Other <small>({genderCount?.other})</small>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
    },
    {
      key: "5",
      label: "Experience",
      children: (
        <div className="checkbox-list experience">
          {/* <Radio.Group
            onChange={handleSearchTypeChange}
            value={searchData?.searchType}
          >
            <Radio value={"any"}>
              Any
              <img src={User3} alt="" />
            </Radio>
            <Radio value={"fresher"}>
              Freshers only <img src={FreshersOnly} alt="" />
            </Radio>
          </Radio.Group> */}

          <Row gutter={[16, 16]}>
            <Col xs={12} xl={12}>
              <Input
                id="minExp"
                placeholder={"Min. in years"}
                maxLength={3}
                value={searchData.minExp}
                onChange={handleInputChange}
              />
            </Col>
            <Col xs={12} xl={12}>
              <Input
                id="maxExp"
                placeholder={"Max. in years"}
                maxLength={3}
                value={searchData.maxExp}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: "6",
      label: "Languages",
      children: (
        <div className="checkbox-list experience">
          <Select
            id="languages"
            mode="multiple"
            showSearch
            className="candidate-select-input"
            placeholder="select Languages"
            onChange={(e) => {
              handleSelectChange(e, { id: "languages" });
            }}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={languagesDropData.map((language) => ({
              value: language.id,
              label: language.name,
              id: "languages",
            }))}
            value={searchData.languages || undefined}
            style={{
              height: "auto",
            }}
          />
        </div>
      ),
    },
    // {
    //   key: "7",
    //   label: "Departments",
    //   children: <p>{text}</p>,
    // },
    // {
    //   key: "8",
    //   label: "Age",
    //   children: <p>{text}</p>,
    // },
  ];

  const onChange = (key) => {
    console.log(key);
  };
  const mediaQuery = useMediaQuery("(max-width: 767px)");

  const formatNumber = (num) => {
    const numStr = num.toString();
    const lastThree = numStr.slice(-3);
    const otherNumbers = numStr.slice(0, -3);

    if (otherNumbers !== "") {
      const formattedOtherNumbers = otherNumbers.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      );
      return formattedOtherNumbers + "," + lastThree;
    }

    return lastThree;
  };

  // const getValuesAsString = (data) => {
  //   const values = [];

  //   for (const key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       const value = data[key];
  //       if (Array.isArray(value)) {
  //         value.forEach((item) => {
  //           if (item) values.push(item);
  //         });
  //       } else {
  //         if (value) values.push(value);
  //       }
  //     }
  //   }

  //   return values.join(", ");
  // };

  const handleBlacklistSuccess = (isSuccess) => {
    if (isSuccess) {
      fetchData(currentPage);
    }
  };

  return (
    <>
      <Flex justify="space-between" align="center" className="candidate-head">
        <Flex align="center">
          <Button
            type="default"
            icon={<img src={angleleftIcon} alt="icon" />}
            size="large"
            onClick={() => {
              navigate("/search-candidates");
            }}
          />

          <p>
            {/* <b>{9,05,323}</b>profiles found for<b>Marketing Executives, Any</b> */}
            <b>{totalCandidates ? formatNumber(totalCandidates) : 0}</b>
            profiles found from{" "}
            <b>{allCandidates ? formatNumber(allCandidates) : 0}</b>
            {/* for
            <b>Marketing Executives, Any</b>
            <b>{getValuesAsString(searchData)}</b> */}
          </p>
        </Flex>
        {/* <Button
          type="default"
          size="large"
          icon={<img src={editIcon} alt="icon" />}
          className="modify-btn"
        >
          Modify Search
        </Button> */}
      </Flex>
      <section className="candidate">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={9} xl={7} className="candidate-mobile">
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col xs={24} lg={15} xl={17}>
            <div className="candidate-right">
              {/* {mediaQuery && (
                <Flex className="mobile-show" align="center" gap="10px">
                  <label>Active in</label>
                  <Select
                    defaultValue="6 months"
                    style={{
                      width: "116px",
                      height: "40px",
                    }}
                    options={[
                      {
                        value: "1",
                        label: "1 Years",
                      },
                      {
                        value: "2",
                        label: "2 Years",
                      },
                    ]}
                  />
                </Flex>
              )} */}
              <div className="candidate-right-head">
                <Checkbox onChange={handleSelectAllChange} checked={selectAll}>
                  Select all
                </Checkbox>

                <Space style={{ gap: "16px" }}>
                  {/* <Flex className="mobile-active-in">
                    <label>Active in</label>
                    <Select
                      defaultValue="6 months"
                      style={{
                        width: "100%",
                      }}
                      options={[
                        {
                          value: "1",
                          label: "1 Years",
                        },
                        {
                          value: "2",
                          label: "2 Years",
                        },
                      ]}
                    />
                  </Flex> */}
                  <Button
                    type="default"
                    size="large"
                    icon={<img src={LeadingIcon} alt="icon" />}
                    className="download-btn"
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                  {/* <Button
                    type="default"
                    size="large"
                    icon={<img src={LeadingIcon} alt="icon" />}
                    className="download-btn"
                    // onClick={handleDownloadAll}
                    onClick={() => {
                      setIsDownloadAllModalOpen(true);
                    }}
                  >
                    Download All
                  </Button> */}
                </Space>
              </div>
              {loading ? (
                <Spin size="large" />
              ) : candidates?.length > 0 ? (
                candidates?.map((candidate) => (
                  <CandidateCard
                    key={candidate.id}
                    candidate={candidate}
                    handleVisibleDetails={handleVisibleDetails}
                    isSelected={selectedCandidates.includes(candidate.id)}
                    onSelectChange={() =>
                      handleCandidateSelectChange(candidate.id)
                    }
                    fetchData={fetchData}
                  />
                ))
              ) : (
                <NoData message="No Results Found" iconSize={60} />
              )}
              {candidates?.length > 0 && (
                <div className="candidate-right-head">
                  <Pagination
                    current={currentPage}
                    total={totalCandidates}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    onShowSizeChange={onPageSizeChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100", "500"]}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <button className="filter-btn btn-visible" onClick={showFilterModal}>
          <img src={filterAquaIcon} alt="" />
        </button>
        <CandidateLocationsModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
        />
        <CandidateDetails
          visibleDetails={visibleDetails}
          candidate={currentCandidate}
          onClose={handleCloseDetails}
          onBlacklistSuccess={handleBlacklistSuccess}
        />
        <div className="mobile-menu">
          {isFilterModalOpen && (
            <div
              className="candidate-details-overlay"
              onClick={() => setIsFilterModalOpen(false)}
            />
          )}
          <div className={`mobile-menu-modal ${isFilterModalOpen && "active"}`}>
            <button className="close" onClick={showFilterModal}>
              <SVGClose />
            </button>
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </section>
      <ConfirmationModal
        isOpen={isDownloadAllModalOpen}
        onConfirm={() => {
          handleDownloadAll();
        }}
        onCancel={() => setIsDownloadAllModalOpen(false)}
        loading={loading}
        title="Confirm Download"
        message={`Are you sure you want to download ${totalCandidates} profiles? Please note that ${totalCandidates} credits will be deducted from your wallet.`}
      />
      <ToastContainer />
    </>
  );
};

export default CandidatePage;
