/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  editIcon,
  eyeIcon,
  mailIcon,
  phoneIcon,
  profileUserIcon,
} from "../assets";
import "../styles/CandidateProfile.css";
import Toast from "../components/Toast/Toast";
import { get, post } from "../lib/Api";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate } from "react-router-dom";
import { Modal, Switch } from "antd";
import ConfirmationModal from "../components/modal/ConfirmationModal";
const s3URL = process.env.REACT_APP_AWS_IMAGE_URL;

dayjs.extend(customParseFormat);

const CandidateProfile = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isResumeModalVisible, setIsResumeModalVisible] = useState(false);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] =
    useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);

  const handleToggle = () => {
    setIsDeactivateModalVisible(true);
  };

  const fetchData = async () => {
    setLoading(true);
    const res = await get(`candidate/profile`, "", {}, true);
    if (res?.status === true) {
      setData(res.profile.results);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeactivateAccount = async () => {
    try {
      setLoading(true);
      const response = await post("candidate/deactivate", "", "", true);
      if (response?.status === true) {
        setIsDeactivated(true);
        localStorage.clear();
        navigate("/login");
      } else {
        Toast({ message: response?.message, type: "error" });
      }
    } catch (error) {
      console.error("Failed to Deactivate Account", error);
    } finally {
      setLoading(false);
    }

    setIsDeactivated(true);
    setIsDeactivateModalVisible(false);
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">My Profile</div>
          <div className="header-subtitle">
            View and update your personal information, contact details, and
            preferences.
          </div>
        </div>
        <div className="button-group">
          <div>
            <button
              className="secondary-button"
              title="Export Job's Data"
              onClick={() => {
                navigate(`/update-candidate/${data?.id}`);
              }}
            >
              <img src={editIcon} alt="Edit Icon" /> Edit
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div style={{ flex: 2 }}>
            <img src={profileUserIcon} alt="User" width="80px" height="80px" />
          </div>
          <div className="candidate-main-profile">
            <div
              style={{
                fontWeight: 600,
                fontSize: "22px",
                lineHeight: "22px",
              }}
            >
              {data?.name}{" "}
            </div>
            {data?.email && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "5px",
                }}
              >
                <img src={mailIcon} alt="Mail Icon" />{" "}
                <span>{data?.email}</span>
              </div>
            )}
            {data?.phone_number && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "5px",
                }}
              >
                <img src={phoneIcon} alt="Phone Icon" />{" "}
                <span>
                  {" "}
                  {data?.phone_number && "+91 " + data?.phone_number}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile-card">
        <div className="profile-card-heading">Personal Information</div>

        <div className="grid-container">
          <div>
            <div className="label">Name</div>
            <div className="value">{data?.name || "N/A"}</div>
          </div>

          <div>
            <div className="label">Email</div>
            <div className="value">{data?.email || "N/A"}</div>
          </div>

          <div>
            <div className="label">Phone</div>
            <div className="value">
              {" "}
              {data?.phone_number ? "+91 " + data?.phone_number : "N/A"}
            </div>
          </div>

          <div>
            <div className="label">Aadhar No.</div>
            <div className="value">{data?.aadhaar_card_number || "N/A"}</div>
          </div>

          <div>
            <div className="label">Date of Birth</div>
            <div className="value">{data?.dob || "N/A"}</div>
          </div>

          <div>
            <div className="label">Gender</div>
            <div className="value">{data?.gender || "N/A"}</div>
          </div>
        </div>
      </div>

      <div className="profile-card">
        <div className="profile-card-heading">Address</div>

        <div className="grid-container">
          <div>
            <div className="label">Country</div>
            <div className="value">{data?.country || "N/A"}</div>
          </div>

          <div>
            <div className="label">State</div>
            <div className="value">{data?.state || "N/A"}</div>
          </div>

          <div>
            <div className="label">City</div>
            <div className="value">{data?.city || "N/A"}</div>
          </div>

          <div>
            <div className="label">Pin Code</div>
            <div className="value">{data?.pin_code || "N/A"}</div>
          </div>
        </div>
      </div>

      <div className="profile-card">
        <div className="profile-card-heading">Other Information</div>

        <div className="grid-container">
          <div>
            <div className="label">Qualification</div>
            <div className="value">{data?.qualification || "N/A"}</div>
          </div>

          <div>
            <div className="label">Designation</div>
            <div className="value">{data?.designation || "N/A"}</div>
          </div>

          <div>
            <div className="label">Current Company</div>
            <div className="value">{data?.current_company || "N/A"}</div>
          </div>

          <div>
            <div className="label">Experience</div>
            <div className="value">{data?.experience || "N/A"}</div>
          </div>

          <div>
            <div className="label">Annual Salary</div>
            <div className="value">{data?.annual_salary || "N/A"}</div>
          </div>

          <div>
            <div className="label">Skills</div>
            <div className="value">
              {data?.skills_name?.join(", ") || "N/A"}
            </div>
          </div>

          <div>
            <div className="label">Preferred Location</div>
            <div className="value">
              {" "}
              {data?.preffered_location_name?.join(", ") || "N/A"}
            </div>
          </div>

          <div>
            <div className="label">Expected Salary</div>
            <div className="value">{data?.expected_salary || "N/A"}</div>
          </div>

          <div>
            <div className="label">Notice Period</div>
            <div className="value">
              {data?.notice_period ? data?.notice_period + " Days" : "N/A"}
            </div>
          </div>
        </div>
      </div>

      {data?.experience_details?.length > 0 && (
        <div className="profile-card">
          <div className="profile-card-heading">Experience Details</div>

          {data?.experience_details
            ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            .map((val, index) => (
              <div
                style={{
                  border: "1px solid gray",
                  borderRadius: "10px",
                  marginTop: "15px",
                  padding: "20px",
                  boxShadow: "2px 2px",
                }}
                key={index}
              >
                <div className="grid-container" style={{ marginTop: "0px" }}>
                  <div>
                    <div className="label">Company Name</div>
                    <div className="value">{val?.companyName || "N/A"}</div>
                  </div>

                  <div>
                    <div className="label">Position</div>
                    <div className="value">{val?.position || "N/A"}</div>
                  </div>

                  <div>
                    <div className="label">Start Date</div>
                    <div className="value">{val?.startDate || "N/A"}</div>
                  </div>

                  <div>
                    <div className="label">End Date</div>
                    <div className="value">{val?.endDate || "N/A"}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {data?.cv && (
        <div className="profile-card">
          <div className="profile-card-heading">Documents</div>

          <div className="grid-container">
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <div>
                <div className="label">Resume</div>
                <div className="value">{`${data?.name}'s Resume`}</div>
              </div>
              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsResumeModalVisible(true);
                  }}
                >
                  <img src={eyeIcon} alt="view icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="profile-card">
        <div className="profile-card-heading">Settings</div>

        <div className="grid-container">
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div>
              <div className="value">Deactivate Account</div>
            </div>

            <div>
              <div style={{ cursor: "pointer" }}>
                <Switch checked={isDeactivated} onChange={handleToggle} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Resume PDF"
        open={isResumeModalVisible}
        // onOk={handleOk}
        onCancel={() => {
          setIsResumeModalVisible(false);
        }}
        width={800}
      >
        <iframe
          // src={baseURL + data?.cv}
          src={`${s3URL + data?.cv}`}
          width="100%"
          height="500px"
          style={{ border: "none" }}
          title="Resume PDF"
        ></iframe>
      </Modal>

      <ConfirmationModal
        isOpen={isDeactivateModalVisible}
        onConfirm={() => {
          handleDeactivateAccount();
        }}
        onCancel={() => setIsDeactivateModalVisible(false)}
        loading={loading}
        title="Confirm Deactivate"
        message="Are you sure you want to deactivate your account? Once deactivated, you will no longer be able to access or log in to your account."
      />

      <ToastContainer />
    </div>
  );
};

export default CandidateProfile;
