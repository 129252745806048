import React, { useState } from "react";
import MainHeader from "../components/Headers/MainHeader";
import "../styles/DashboardLayout.css";
import Sidebar from "../components/Headers/Sidebar";

function DashboardLayout({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="dashboard-layout">
      <MainHeader className="main-header" handleMenu={handleMenu} />
      <div className="content">
        <Sidebar className="sidebar" menuOpen={menuOpen} />
        <main
          className="main-content"
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          {children}
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
