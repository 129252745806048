/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles/CreditUsagePage.css";
import { ToastContainer } from "react-toastify";
import { DatePicker, Table, Tabs } from "antd";
// import { InfoCircleOutlined } from "@ant-design/icons";
import { post } from "../lib/Api";
import Toast from "../components/Toast/Toast";
import dayjs from "dayjs";

const CreditUsagePage = () => {
  const dateFormat = "DD/MM/YYYY";
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");
  const [dateFilter, setDateFilter] = useState({
    fromDate: null,
    toDate: null,
  });

  const [creditCount, setCreditCount] = useState({
    totalCredit: 0,
    avalilableCredit: 0,
    usedCredit: 0,
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  const columns = [
    {
      title: "Transaction Details",
      width: "37%",
      key: "transaction_details",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div><img src={profileUserIcon} alt="User Icon"/></div> */}
          <div style={{ paddingLeft: "10px" }}>
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`Credits ${result.transaction_type}`}</div>
            <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}
            >
              {result.credit_type}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Credits",
      dataIndex: "credits",
      width: "19%",
      // align: "center",
      key: "credits",
    },
    {
      title: "User",
      key: "name",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`${result?.user_name ? result?.user_name : "-"}`}</div>
        </div>
      ),
      width: "26%",
    },
    {
      title: "Transaction Date",
      dataIndex: "created_at",
      width: "18%",
      // align: "center",
      key: "created_at",
      sorter: true,
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingLeft: "10px" }}>
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{dayjs(result).format("DD-MM-YYYY")}</div>
            <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px", color: "#726C6C" }}
            >
              {dayjs(result).format("hh:mm A")}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const getRandomuserParams = (params) => ({
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    sort_order: params.sortOrder,
    type: selectedOption,
    fromDate: dateFilter.fromDate ? dateFilter.fromDate.format(dateFormat) : "",
    toDate: dateFilter.toDate ? dateFilter.toDate.format(dateFormat) : "",
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await post(
      `user/credits-history-list`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res.credits_history_list.results);
      setCreditCount({
        totalCredit: res.credits.total,
        avalilableCredit: res.credits.available,
        usedCredit: res.credits.used
      })
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    selectedOption,
    dateFilter,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleDateChange = (date, id) => {
    setDateFilter({
      ...dateFilter,
      [id]: date,
    });
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="title">Credits & Usage</div>
          <div className="subtitle">
            Monitor and manage credits and usage to ensure optimal resource
            allocation and control.
          </div>
        </div>
        <div className="button-group">
          <button className="primary-button">Contact Sales</button>
        </div>
      </div>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Credits Overview" key="1">
          <div className="tab-content">
            <div className="section-title">Available Credits</div>
            <div className="section-subtitle">
              Credits are charged for each job posting access, database unlock,
              and WhatsApp invite{" "}
            </div>
            <div className="credits-container">
              <div className="credit-card">
                <div className="credit-card-header">
                  {/* <InfoCircleOutlined className="credit-card-icon" /> */}
                  Total Credits
                </div>
                <div className="credit-card-body">
                  <div className="credits-number">
                    {creditCount?.totalCredit} <span>credits</span>
                  </div>
                  {/* <div className="credits-expiry">
                    106 credits will expire on Sep 03, 2024
                  </div> */}
                  {/* <a href="#" className="view-all">
                    View all
                  </a> */}
                </div>
              </div>
              <div className="credit-card">
                <div className="credit-card-header">
                  {/* <InfoCircleOutlined className="credit-card-icon" /> */}
                  Available Credits
                </div>
                <div className="credit-card-body">
                  <div className="credits-number">
                    {creditCount?.avalilableCredit} <span>credits</span>
                  </div>
                  {/* <div className="credits-expiry">
                    4414 credits will expire on Sep 03, 2024
                  </div> */}
                  {/* <a href="#" className="view-all">
                    View all
                  </a> */}
                </div>
              </div>
              <div className="credit-card">
                <div className="credit-card-header">
                  {/* <InfoCircleOutlined className="credit-card-icon" /> */}
                 Used Credits
                </div>
                <div className="credit-card-body">
                  <div className="credits-number">
                    {creditCount?.usedCredit} <span>credits</span>
                  </div>
                  {/* <div className="credits-expiry">
                    2500 credits will expire on Sep 03, 2024
                  </div> */}
                  {/* <a href="#" className="view-all">
                    View all
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="tab-content">
            <div className="section-title">Usage limits</div>
            <div className="section-subtitle">
              The usage limit resets at 12:00 am on the first day of every
              month. | Next Reset: July 1, 2024
            </div>
            <div className="usage-limits-container">
              <div className="usage-limit-card">
                <div className="usage-limit-header">Job Credits</div>
                <div className="usage-limit-body">
                  <div className="usage-number">
                    20 <span>credits</span>
                  </div>
                  <div className="usage-info">Per month/per user</div>
                </div>
              </div>
              <div className="usage-limit-card">
                <div className="usage-limit-header">Candidates Credits</div>
                <div className="usage-limit-body">
                  <div className="usage-number">
                    100 <span>credits</span>
                  </div>
                  <div className="usage-info">Per month/per user</div>
                </div>
              </div>
              <div className="usage-limit-card">
                <div className="usage-limit-header">
                  WhatsApp Invite Credits
                </div>
                <div className="usage-limit-body">
                  <div className="usage-number">
                    100 <span>credits</span>
                  </div>
                  <div className="usage-info">Per month/per user</div>
                </div>
              </div>
            </div>
          </div> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Usage History" key="2">
          <div>Usage History</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transaction History" key="3">
          <div className="filter-section">
            <div className="filter-bar">
              <div className="credit-filter-group">
                <div>
                  <div style={{ display: "flex" }}>
                    <div
                      className={`divStyle ${
                        selectedOption === "all" ? "selectedDivStyle" : ""
                      }`}
                      onClick={() => handleSelect("all")}
                    >
                      All
                    </div>
                    <div
                      className={`divStyle ${
                        selectedOption === "add" ? "selectedDivStyle" : ""
                      }`}
                      onClick={() => handleSelect("add")}
                    >
                      Credits added
                    </div>
                    <div
                      className={`divStyle ${
                        selectedOption === "spent" ? "selectedDivStyle" : ""
                      }`}
                      onClick={() => handleSelect("spent")}
                    >
                      Credits spent
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <label htmlFor="from" className="date-label">
                      From
                    </label>
                    <DatePicker
                      id="from"
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      className="credit-date-input"
                      format={dateFormat}
                      value={dateFilter.fromDate}
                      onChange={(date) => handleDateChange(date, "fromDate")}
                    />
                  </div>
                  <div>
                    <label htmlFor="to" className="date-label">
                      To
                    </label>
                    <DatePicker
                      id="to"
                      disabledDate={(current) => {
                        return current < dateFilter.fromDate;
                      }}
                      className="credit-date-input"
                      format={dateFormat}
                      value={dateFilter.toDate}
                      onChange={(date) => handleDateChange(date, "toDate")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container">
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <ToastContainer />
    </div>
  );
};

export default CreditUsagePage;
