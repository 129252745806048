import React from 'react';
import { nodataIcon } from '../../assets';

const NoData = ({ message = "No Data", iconSize = 60, containerStyle = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#ffff",
        padding: "30px",
        ...containerStyle, 
      }}
    >
      <div>
        <img src={nodataIcon} alt="No Data" width={iconSize} height={iconSize} />
      </div>
      <div>{message}</div>
    </div>
  );
};

export default NoData;
