import React, { useEffect, useState } from "react";
import { twoUserIcon } from "../assets";
import "../styles/RecruitersPage.css";
import { ToastContainer } from "react-toastify";
import { get } from "../lib/Api";
import Toast from "../components/Toast/Toast";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  const [dashboardCount, setDashboardCount] = useState({
    totalJobs: "",
    totalCandidate: "",
    totalClient: "",
    totalRecruiter: "",
  });

  const fetchDashboardCount = async () => {
    setLoading(true);
    const res = await get(`user/dashboard`, null, {}, true);
    if (res?.status === true) {
      setDashboardCount({
        totalJobs: res.data.jobs,
        totalCandidate: res.data.candidates,
        totalClient: res.data.clients,
        totalRecruiter: res.data.recruiters,
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardCount();
  }, []);

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Your Dashboard</div>
          <div className="header-subtitle">
            Your command center for data-driven decisions.
          </div>
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Jobs </div>
            <div className="value">{dashboardCount.totalJobs}</div>
          </div>
        </div>

        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Candidate </div>
            <div className="value">{dashboardCount.totalCandidate}</div>
          </div>
        </div>

        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Client </div>
            <div className="value">{dashboardCount.totalClient}</div>
          </div>
        </div>

        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Recruiter </div>
            <div className="value">{dashboardCount.totalRecruiter}</div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
