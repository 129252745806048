/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../styles/SearchCandidatePage.css";
import {
  Button,
  Col,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Checkbox,
} from "antd";
import { searchIcon, timeSearchIcon } from "../assets";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "../hooks/useMediaQuery";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import { get } from "../lib/Api";
const { Option, OptGroup } = Select;
// const tagRender = (props) => {
//   const { label, value, closable, onClose } = props;
//   const onPreventMouseDown = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//   };
//   return (
//     <Tag
//       color={"#E5F8FF"}
//       onMouseDown={onPreventMouseDown}
//       closable={closable}
//       onClose={onClose}
//       closeIcon={<CloseOutlined style={{ color: "#0079A8" }} />}
//       style={{
//         marginInlineEnd: 10,
//         color: "#0079A8",
//         padding: "3px",
//       }}
//     >
//       {label}
//     </Tag>
//   );
// };

const SearchCandidatePage = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery("(max-width: 767px)");

  const [loading, setLoading] = useState(false);
  const [educationDropData, setEducationDropData] = useState([]);
  const [keywordInputValue, setKeywordInputValue] = useState("");

  const [selectedCityItems, setSelectedCityItems] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  // const [selectedCityItems, setSelectedCityItems] = useState([]);
  // const [cityToStateMap, setCityToStateMap] = useState({});
  // const [stateToCityMap, setStateToCityMap] = useState({});
  const [statesWithCities, setStatesWithCities] = useState([]);

  const [searchData, setSearchData] = useState({
    // searchType: "any",
    keywords: [],
    currentCity: [],
    minExp: "",
    maxExp: "",
    minSalary: "",
    maxSalary: "",
    highestEducaton: [],
    industries: [],
    gender: "",
    languages: [],
  });

  const [recentSearchData, setRecentSearchData] = useState([]);

  const fetchStatesWithCities = async () => {
    setLoading(true);
    const res = await get(`master/state-wise-city-list`, {}, {}, true);
    if (res?.status === true) {
      setStatesWithCities(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchEducationDropData = async () => {
    setLoading(true);
    const res = await get(`master/qualification-list`, "", {}, true);
    if (res?.status === true) {
      setEducationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchRecentSearchData = async () => {
    setLoading(true);
    const res = await get(`candidate/recent-search-history-list`, "", {}, true);
    if (res?.status === true) {
      setRecentSearchData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducationDropData();
    fetchStatesWithCities();
    fetchRecentSearchData();
  }, []);

  const fillSearch = (search) => {
    setSearchData({
      // ...searchData,
      keywords: search.recent_search.keywords,
      currentCity: search.recent_search.currentCity,
      minExp: search.recent_search.minExp,
      maxExp: search.recent_search.maxExp,
      minSalary: search.recent_search.minSalary,
      maxSalary: search.recent_search.maxSalary,
      highestEducaton: search.recent_search.highestEducaton,
    });

    setSelectedCityItems(search.recent_search.currentCity);
  };

  const handleKeyPress = (e) => {
    if (
      (e.key === "Enter" && keywordInputValue.trim() !== "") ||
      (e.key === "Tab" && keywordInputValue.trim() !== "")
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  const handleKeywordInputChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const handleSelectChange = (value, option) => {
    setSearchData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleKeywordRemove = (index) => {
    setSearchData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "minSalary" || id === "maxSalary") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setSearchData({
          ...searchData,
          [id]: value,
        });
      }
    } else if (id === "minExp" || id === "maxExp") {
      if (/^\d+(\.\d{0,2})?$/.test(value) || value === "") {
        setSearchData({
          ...searchData,
          [id]: value,
        });
      }
    } else {
      setSearchData({
        ...searchData,
        [id]: value,
      });
    }
  };

  const hasValidSearchData = (data) => {
    return Object.values(data).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== "";
    });
  };

  const handleSearchCandidates = (event) => {
    event.preventDefault();

    if (hasValidSearchData(searchData)) {
      navigate("/candidates", { state: { searchData } });
    } else {
      Toast({
        message: "Please fill in at least one field to show candidates",
        type: "error",
      });
    }
  };

  const handleResetData = async () => {
    setSearchData({
      // searchType: "any",
      keywords: [],
      currentCity: [],
      minExp: "",
      maxExp: "",
      minSalary: "",
      maxSalary: "",
      highestEducaton: [],
      industries: [],
      Gender: "",
      languages: [],
    });
    setSelectedCityItems([]);
  };

  useEffect(() => {
    const newCityToStateMap = {};
    const newStateToCityMap = {};
    statesWithCities.forEach((state) => {
      newStateToCityMap[state.id] = state.cities.map((city) => city.id);
      state.cities.forEach((city) => {
        newCityToStateMap[city.id] = state.id;
      });
    });
    // setCityToStateMap(newCityToStateMap);
    // setStateToCityMap(newStateToCityMap);
  }, [statesWithCities]);

  // const handleCityChange = (values) => {
  //   setSelectedCityItems(values);
  //   setSearchData((prevData) => ({ ...prevData, currentCity: values }));
  // };

  const handleCityChange = (value) => {
    setSelectedCityItems(value);
  
    const newlySelectedStates = statesWithCities
      .filter((state) => state.cities.every((city) => value.includes(city.id)))
      .map((state) => state.id);
  
    setSelectedStates(newlySelectedStates);
    setSearchData({
      ...searchData,
      currentCity: value,
    });
  };

  const handleStateChange = (stateId, stateCities) => {
    const cityIds = stateCities.map((city) => city.id);
  
    if (selectedStates.includes(stateId)) {
      setSelectedCityItems((prev) =>
        prev.filter((cityId) => !cityIds.includes(cityId))
      );
      setSelectedStates((prev) => prev.filter((id) => id !== stateId));
  
      setSearchData((prev) => ({
        ...prev,
        currentCity: prev.currentCity.filter((cityId) => !cityIds.includes(cityId)),
      }));
    } else {
      const newCityItems = [...new Set([...selectedCityItems, ...cityIds])];
      setSelectedCityItems(newCityItems);
      setSelectedStates((prev) => [...prev, stateId]);
  
      setSearchData((prev) => ({
        ...prev,
        currentCity: newCityItems,
      }));
    }
  };

  // const handleCitySelect = (value) => {
  //   const stateId = cityToStateMap[value];
  //   if (stateId) {
  //     setSelectedCityItems((prev) => [...new Set([...prev, value, stateId])]);
  //   }
  // };

  // const handleDeselect = (value) => {
  //   const stateId = cityToStateMap[value];
  //   if (stateId) {
  //     const remainingCities = stateToCityMap[stateId].filter(
  //       (id) => id !== value
  //     );
  //     if (!remainingCities.some((id) => selectedCityItems.includes(id))) {
  //       setSelectedCityItems((prev) => prev.filter((item) => item !== stateId));
  //     }
  //   }
  // };

  // const getOptions = () => {
  //   return statesWithCities.map((state) => (
  //     <OptGroup
  //       key={state.id}
  //       label={
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <Checkbox
  //             checked={selectedCityItems.includes(state.id)}
  //             style={{ marginRight: 8 }}
  //           />
  //           {state.name}
  //         </div>
  //       }
  //     >
  //       {state.cities.map((city) => (
  //         <Option key={city.id} value={city.id}>
  //           {city.name}
  //         </Option>
  //       ))}
  //     </OptGroup>
  //   ));
  // };

  const getOptions = () => {
    return statesWithCities
      .filter((state) => state.cities.length > 0)
      .map((state) => {
        const allCitiesSelected = state.cities.every((city) =>
          selectedCityItems.includes(city.id)
        );
  
        return (
          <OptGroup
            key={state.id}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={allCitiesSelected}
                  onChange={() => handleStateChange(state.id, state.cities)}
                  style={{ marginRight: 8 }}
                />
                {state.name}
              </div>
            }
          >
            {state.cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </OptGroup>
        );
      });
  };

  // const customTagRender = ({ label, value, closable, onClose }) => {
  //   const shouldHide = label.startsWith("s-");
  //   if (shouldHide) {
  //     return null;
  //   }
  //   return (
  //     <Tag closable={closable} onClose={onClose}>
  //       {label}
  //     </Tag>
  //   );
  // };

  const filterOption = (input, option) => {
    const state = statesWithCities.find((state) =>
      state.cities.some((city) => city.id === option.value)
    );
  
    const cityNameMatches = option?.children?.toLowerCase().includes(input.toLowerCase());
    const stateNameMatches = state?.name?.toLowerCase().includes(input.toLowerCase());
  
    return cityNameMatches || stateNameMatches;
  };
  
  return (
    <section className="search-candidate">
      <div className="header">
        <h2 className="header-title">Search Candidates</h2>
      </div>
      <Row gutter={[20, 20]} className="search-candidate-row">
        <Col xs={24} lg={15} xl={17}>
          <div className="header mobile-show-header">
            <h2 className="header-title">Search Candidates</h2>
          </div>
          <div className="search-bg-wrapper">
            {/* <div className="head space">
              <h3>Searching for</h3>
              <Radio.Group
                onChange={handleSearchTypeChange}
                value={searchData.searchType}
              >
                <Radio value={"fresher"}>
                  <p>
                    <img src={FreshersOnly} alt="" />
                    Freshers only
                  </p>
                </Radio>
                <Radio value={"experience"}>
                  <p>
                    <img src={User} alt="" />
                    Experienced only
                  </p>
                </Radio>
                <Radio value={"any"}>
                  <p>
                    <img src={User3} alt="" />
                    Any
                  </p>
                </Radio>
              </Radio.Group>
            </div> */}
            <div className="search-keyword space">
              <div className="form-group" ref={inputRef}>
                <label htmlFor="Keywords">
                  Keywords <span className="required">*</span>
                </label>
                <Input
                  id="Keywords"
                  placeholder="Type Keyword to search"
                  value={keywordInputValue}
                  onChange={handleKeywordInputChange}
                  onKeyDown={handleKeyPress}
                />
              </div>

              <Space direction="horizontal" className="tags-wrapper">
                {searchData?.keywords.map((val, index) => (
                  <Tag
                    // closeIcon={<SVGClose />}
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleKeywordRemove(index);
                    }}
                    color="success"
                    key={index}
                  >
                    {val}
                  </Tag>
                ))}
              </Space>
            </div>
            <div className="search-keyword space">
              <div className="form-group">
                <label htmlFor="CurrentCity">Current city</label>

                {/* <Select
                  mode="multiple"
                  placeholder="Select cities"
                  value={selectedCityItems}
                  onChange={handleCityChange}
                  onSelect={handleCitySelect}
                  onDeselect={handleDeselect}
                  style={{ width: "100%" }}
                  tagRender={customTagRender}
                  showSearch
                  filterOption={filterOption}
                >
                  {getOptions()}
                </Select> */}

                <Select
                  mode="multiple"
                  placeholder="Select cities"
                  value={selectedCityItems}
                  onChange={handleCityChange}
                  style={{ width: "100%", height: "auto" }}
                  showSearch
                  filterOption={filterOption}
                >
                  {getOptions()}
                </Select>
              </div>
              {/* <Space direction="horizontal" className="tags-wrapper">
                <Tag
                  closeIcon={<SVGClose />}
                  onClose={console.log}
                  color="success"
                >
                  Ahmedabad
                </Tag>
                <Tag
                  closeIcon={<SVGClose />}
                  onClose={console.log}
                  color="success"
                >
                  Gurugram
                </Tag>
              </Space> */}
            </div>
            <div className="search-keyword space">
              <div className="form-group">
                <label htmlFor="Experience">Experience</label>

                <Row gutter={[16, 16]}>
                  <Col xs={12} xl={12}>
                    <Input
                      id="minExp"
                      placeholder={
                        mediaQuery
                          ? "Min. in years"
                          : "Minimum Experience in years"
                      }
                      maxLength={3}
                      value={searchData.minExp}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col xs={12} xl={12}>
                    <Input
                      id="maxExp"
                      placeholder={
                        mediaQuery
                          ? "Max. in years"
                          : "Maximum Experience in years"
                      }
                      maxLength={3}
                      value={searchData.maxExp}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label htmlFor="AnnualSalary">Annual Salary</label>
                <Row gutter={[16, 16]}>
                  <Col xs={12} xl={12}>
                    {/* <Select
                      defaultValue={
                        mediaQuery ? "Min. in lakhs" : "Minimum salary in lakhs"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    /> */}

                    <Input
                      id="minSalary"
                      placeholder={
                        mediaQuery ? "Min. in lakhs" : "Minimum salary in lakhs"
                      }
                      value={searchData.minSalary}
                      onChange={handleInputChange}
                      // onKeyDown={handleKeyPress}
                    />
                  </Col>
                  <Col xs={12} xl={12}>
                    {/* <Select
                      defaultValue={
                        mediaQuery ? "Max. in lakhs" : "Maximum salary in lakhs"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    /> */}
                    <Input
                      id="maxSalary"
                      placeholder={
                        mediaQuery ? "Max. in lakhs" : "Maximum salary in lakhs"
                      }
                      value={searchData.maxSalary}
                      onChange={handleInputChange}
                      // onKeyDown={handleKeyPress}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label htmlFor="highestEducaton">Highest Education</label>
                <Select
                  id="highestEducaton"
                  mode="multiple"
                  placeholder="Select a Education"
                  className="candidate-select-input"
                  onChange={(e) => {
                    handleSelectChange(e, { id: "highestEducaton" });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={educationDropData.map((education) => ({
                    value: education.id,
                    label: education.name,
                    id: "highestEducaton",
                  }))}
                  value={searchData.highestEducaton || undefined}
                  style={{
                    height: "auto",
                  }}
                />

                {/* <Space direction="horizontal" className="tags-wrapper">
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    10th pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                </Space> */}

                {/* <Button type="link" block>
                  View More
                </Button> */}
              </div>
            </div>
            <div className="search-bg-footer">
              <div className="candidate-right-head">
                <Flex
                  justify="space-between"
                  align="center"
                  wrap
                  style={{ gap: "10px" }}
                >
                  <Flex
                    align="center"
                    style={{ gap: "16px" }}
                    className="active-in"
                  >
                    {/* <label>Active in</label>
                    <Select
                      defaultValue="6 months"
                      style={{
                        width: "100%",
                      }}
                      options={[
                        {
                          value: "1",
                          label: "1 Years",
                        },
                        {
                          value: "2",
                          label: "2 Years",
                        },
                      ]}
                    /> */}{" "}
                    &nbsp;
                  </Flex>
                  <Flex align="center" style={{ gap: "16px" }}>
                    <Button type="text" size="large" onClick={handleResetData}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      icon={<img src={searchIcon} alt="icon" />}
                      onClick={handleSearchCandidates}
                    >
                      Search Candidates
                    </Button>
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={9} xl={7}>
          <div className="search-candidate-right">
            <div className="search-bg-wrapper mobile-search-wrapper">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={timeSearchIcon} alt="" />
                  Recent searches
                </h3>
                {/* <Button type="link" block>
                  View All
                </Button> */}
              </Flex>
              <div className="candidate-recent-search">
                {/* <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul> */}
                {recentSearchData.map((search) => (
                  <ul key={search.id}>
                    <li>{search.recent_search.keywords.join(" | ")}</li>
                    <li>
                      <div>
                        <Button
                          type="link"
                          block
                          onClick={() => fillSearch(search)}
                        >
                          Fill search
                        </Button>
                        {/* <Button type="link" block>
                          Search candidates
                        </Button> */}
                      </div>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </section>
  );
};

export default SearchCandidatePage;
