/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Pagination, Spin } from "antd";
import CandidateLocationsModal from "../components/CandidateLocationsModal";
import "../styles/CandidatePage.css";
import { get, post } from "../lib/Api";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import CandidateAppliedJobCard from "../components/CandidateCard/CandidateAppliedJobCard";
import NoData from "../components/NoData/NoData";

const CandidateAppliedJobs = () => {
  const inputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [searchData, setSearchData] = useState({
    keywords: [],
    location: [],
    minExp: "",
    maxExp: "",
  });

  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const [statesWithCities, setStatesWithCities] = useState([]);

  const fetchData = async (page) => {
    setLoading(true);
    const data = { ...searchData, page_no: page, page_size: pageSize };
    try {
      const response = await post(
        "candidate/applied-jobs-list",
        data,
        "",
        true
      );
      if (response?.status === true) {
        setJobs(response.applied_jobs_list.results);
        setTotalJobs(response.count);
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatesWithCities = async () => {
    setLoading(true);
    const res = await get(`master/state-wise-city-list`, {}, {}, true);
    if (res?.status === true) {
      setStatesWithCities(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, pageSize, searchData]);

  useEffect(() => {
    fetchStatesWithCities();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(current);
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  useEffect(() => {
    const newCityToStateMap = {};
    const newStateToCityMap = {};
    statesWithCities.forEach((state) => {
      newStateToCityMap[state.id] = state.cities.map((city) => city.id);
      state.cities.forEach((city) => {
        newCityToStateMap[city.id] = state.id;
      });
    });
  }, [statesWithCities]);

  const handleCandidateSelectChange = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const handleOk = () => setIsModalOpen(false);

  return (
    <>
      <section className="candidate">
        <div className="header">
          <div>
            <div className="header-title">Applied Jobs</div>
            <div className="header-subtitle">
              Review the jobs you have applied for and track your application
              status.
            </div>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} lg={9} xl={7} className="candidate-mobile">
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </Col> */}
          <Col xs={24} lg={15} xl={17}>
            <div className="candidate-right">
              {/* <div className="candidate-right-head"></div> */}
              {loading ? (
                <Spin size="large" />
              ) : jobs.length > 0 ? (
                jobs?.map((job) => (
                  <CandidateAppliedJobCard
                    key={job.id}
                    job={job}
                    // handleVisibleDetails={handleVisibleDetails}
                    isSelected={selectedCandidates.includes(job.id)}
                    onSelectChange={() => handleCandidateSelectChange(job.id)}
                    fetchData={fetchData}
                  />
                ))
              ) : (
                <NoData message="No Results Found" iconSize={60} />
              )}
              {jobs.length > 0 && (
                <div className="candidate-right-head">
                  <Pagination
                    current={currentPage}
                    total={totalJobs}
                    pageSize={pageSize}
                    onChange={onPageChange}
                    onShowSizeChange={onPageSizeChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100", "500"]}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        {/* <button className="filter-btn btn-visible" onClick={showFilterModal}>
          <img src={filterAquaIcon} alt="" />
        </button> */}
        <CandidateLocationsModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
        />
        {/* <div className="mobile-menu"> */}
        {/* {isFilterModalOpen && (
            <div
              className="candidate-details-overlay"
              onClick={() => setIsFilterModalOpen(false)}
            />
          )} */}
        {/* <div className={`mobile-menu-modal ${isFilterModalOpen && "active"}`}>
            <button className="close" onClick={showFilterModal}>
              <SVGClose />
            </button>
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </div> */}
        {/* </div> */}
      </section>
      <ToastContainer />
    </>
  );
};

export default CandidateAppliedJobs;
